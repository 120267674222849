import { Spinner } from '@finn/design-system/atoms/spinner';
import { Grid } from '@material-ui/core';
import React from 'react';

import BottomMarker from '~/components/BottomMarker';

type Props = {
  isLoading: boolean;
  loadNextPage(): void;
};

export const ProductListInfiniteScrollTrigger: React.FC<Props> = ({
  isLoading,
  loadNextPage,
}) => {
  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <div className="text-center">
            <Spinner className=" fill-trustedBlue h-12 w-12" />
          </div>
        </Grid>
      )}

      {!isLoading && <BottomMarker onReached={loadNextPage} />}
    </>
  );
};
