import { useCurrentLocale } from '@finn/ui-utils';
import React, { memo, PropsWithChildren, useMemo } from 'react';

import { FilterProvider } from '../context';
import { PathFilterMapper } from '../mappers/PathFilterMapper';
import { useFiltersResponse } from '../utils';

type PathFilterProviderProps = {
  basePathname: string;
  isOutOfStock?: boolean;
};

export const PathFilterProvider: React.FunctionComponent<
  PropsWithChildren<PathFilterProviderProps>
> = memo(function PathFilterProvider({ basePathname, isOutOfStock, children }) {
  const { locale } = useCurrentLocale();
  const filtersResponse = useFiltersResponse(isOutOfStock);

  const filterMapper = useMemo(
    () => new PathFilterMapper(basePathname, locale, filtersResponse),
    [basePathname, filtersResponse, locale]
  );

  return (
    <FilterProvider filterMapper={filterMapper}>{children}</FilterProvider>
  );
});
