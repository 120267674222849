import React from 'react';

import {
  GetProductListItemsResponse,
  useProductListItems,
  UseProductListItemsParams,
} from '../api';

type RenderPropParams = ReturnType<typeof useProductListItems>;

type Props = UseProductListItemsParams & {
  infinite?: boolean;
  fallbackData?: GetProductListItemsResponse;
  children: (params: RenderPropParams) => JSX.Element;
};

export const ProductListConsumer: React.FC<Props> = ({
  infinite,
  fallbackData,
  children,
  ...useProductListParams
}) => {
  return children(
    useProductListItems(
      {
        zipCode: undefined,
        ...useProductListParams,
      },
      { infinite, fallbackData }
    )
  );
};
