export const firstLvlPathParamsKeys = {
  BRANDS: 'brands',
  MODELS: 'models',
};
export const secondLvlPathParamsKeys = [
  'cartypes',
  'gearshifts',
  'fuels',
  'terms',
  'coming-soon',
  'retention',
  'hitch',
  'young-driver',
];
export const thirdLvlQueryParamsKeys = [
  'sort',
  'is_for_business',
  'view',
  'min_price',
  'max_price',
  'available_from',
  'available_to',
  'payment_method',
];
