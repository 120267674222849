import { useIntersectionObserver } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

interface IProps {
  onReached: Function;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
}));

const BottomMarker: React.FunctionComponent<IProps> = ({ onReached }) => {
  const classes = useStyles();
  const markerRef = useRef<HTMLDivElement | null>(null);

  const isInView = useIntersectionObserver(markerRef, 0, '0px');
  useEffect(() => {
    if (isInView) {
      onReached();
    }
  }, [isInView, onReached]);

  return (
    <div
      ref={markerRef}
      className={classes.wrapper}
      data-appid="bottom-marker"
    />
  );
};

export default React.memo(BottomMarker);
