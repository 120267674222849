import { FilterValuesObject } from '@finn/ua-vehicle';
import * as React from 'react';
import { createContext, FC, memo, PropsWithChildren, useContext } from 'react';

const ImplicitFilterContext = createContext<FilterValuesObject>({});

type Props = {
  filters: FilterValuesObject;
};

export const ImplicitFilterProvider: FC<PropsWithChildren<Props>> = memo(
  function ImplicitFilterProvider({ filters, children }) {
    return (
      <ImplicitFilterContext.Provider value={filters}>
        {children}
      </ImplicitFilterContext.Provider>
    );
  }
);

export function useImplicitFilters(): FilterValuesObject {
  return useContext(ImplicitFilterContext);
}
