import { FiltersResponse, FilterValuesObject } from '@finn/ua-vehicle';
import { useEffect } from 'react';

import { trackProductListFiltered } from '../modules/products/filters/tracking';
import { haveFilterValuesChanged } from '../modules/products/filters/utils';

export const useTrackingEffect = (
  total: number,
  isRouterReady: boolean,
  filtersResponse: FiltersResponse,
  trackedFilterValues: FilterValuesObject,
  previousFilterValues: FilterValuesObject
) => {
  useEffect(() => {
    if (!isRouterReady) {
      return;
    }

    if (haveFilterValuesChanged(trackedFilterValues, previousFilterValues)) {
      trackProductListFiltered(trackedFilterValues, filtersResponse, total);
    }
  }, [
    total,
    isRouterReady,
    filtersResponse,
    trackedFilterValues,
    previousFilterValues,
  ]);
};
