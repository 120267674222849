import { FilterKey, FilterValuesObject } from '@finn/ua-vehicle';
import { useIsAppReady } from '@finn/ui-modules';
import React, { memo, useEffect } from 'react';

import { useFilterValues, useReplaceFilterValues } from '../context';

type Props = {
  defaultFilterValues: FilterValuesObject;
};

export const RestoreDefaultFilters: React.FC<Props> = memo(
  function RestoreFiltersOnMount({ defaultFilterValues }) {
    const isAppReady = useIsAppReady();
    const replaceFilterValues = useReplaceFilterValues();

    const filtersCount = useFilterValues(
      (filters) => Object.keys(filters).length
    );

    useEffect(() => {
      const nextFilterValues: FilterValuesObject = {
        ...defaultFilterValues,
        // TODO: Base this on implicit filters
        [FilterKey.HAS_DEALS]: undefined,
        [FilterKey.PRODUCT_GROUP]: undefined,
        [FilterKey.HAS_RETENTION_DEALS]: undefined,
      };

      if (!isAppReady || filtersCount !== 0) {
        return;
      }

      replaceFilterValues(nextFilterValues);
    }, [isAppReady, filtersCount, defaultFilterValues]);

    return null;
  }
);
