import get from 'lodash/get';

import {
  getHeadline,
  getMetaDataKey,
  getMinGrossPrice,
  getSeoHeader,
} from '~/utils/plp/plp';

export const getContentPieces = async ({
  pageData,
  initialFilter = {},
  totalResults,
  minPriceInFilter,
  initialFilterKeysMap = {},
  isForBusiness = false,
}) => {
  const metadataKey = getMetaDataKey(initialFilter);
  let seoHeader = get(pageData, `metadata.${metadataKey}`, {});
  if (metadataKey !== 'fallback_template_plp') {
    const minPrice = `${getMinGrossPrice(minPriceInFilter, isForBusiness)} €`;
    seoHeader = getSeoHeader({
      vehiclesCount: totalResults,
      minPrice,
      seoHeader,
      filters: initialFilter,
      initialFilterKeysMap,
    });
  }

  const headline = await getHeadline(
    pageData,
    initialFilter,
    initialFilterKeysMap
  );

  const countTemplate = get(pageData, 'metadata.count_placeholder', '');

  const fallBackSeoHeader = get(pageData, 'metadata.fallback_template_plp', {});

  return {
    seoHeader,
    headline,
    countTemplate,
    metadataKey,
    fallBackSeoHeader,
  };
};
