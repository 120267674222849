import { FilterValuesObject } from '@finn/ua-vehicle';

import {
  createFilterConsumer,
  createFilterContext,
  createFilterHooks,
  createFilterProvider,
} from './lib';

export const FilterContext = createFilterContext<FilterValuesObject>();

export const FilterProvider = createFilterProvider(FilterContext);
export const FilterConsumer = createFilterConsumer(FilterContext);

const { useFilterValues, useMergeFilterValues, useReplaceFilterValues } =
  createFilterHooks<FilterValuesObject>(FilterContext);

export { useFilterValues, useMergeFilterValues, useReplaceFilterValues };
