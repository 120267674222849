import {
  FilterKey,
  FilterValuesObject,
  parseFilterValues,
} from '@finn/ua-vehicle';
import { parse, stringify } from 'query-string';

export abstract class FilterMapper {
  protected basePathname: string;

  constructor(basePathname: string) {
    this.basePathname = basePathname;
  }

  public abstract serialize(filterMap: FilterValuesObject): string;

  public abstract deserialize(url: string): FilterValuesObject;

  protected static stringifyQueryString(value: object): string {
    const adjustedValue = Object.assign({}, value);
    if (FilterKey.SORT in adjustedValue) {
      // skip default values in query string
      if (adjustedValue[FilterKey.SORT] === 'default') {
        adjustedValue[FilterKey.SORT] = undefined;
      }
    }

    const qs = stringify(adjustedValue, {
      arrayFormat: 'comma',
      skipNull: true,
    });

    return qs ? `?${qs}` : '';
  }

  protected static parseQueryString(value: string): object {
    const qsValue = value.split('?')[1];
    if (!qsValue) {
      return {};
    }

    return parse(qsValue, { arrayFormat: 'comma' });
  }

  protected static parseFilterMap(input: object): FilterValuesObject {
    return parseFilterValues(input);
  }
}
