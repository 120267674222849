import { FilterValuesObject } from '@finn/ua-vehicle';

import { FilterMapper } from './FilterMapper';

export class QueryFilterMapper extends FilterMapper {
  public serialize(filterValues: FilterValuesObject): string {
    const queryParams = FilterMapper.stringifyQueryString(filterValues);

    return `${this.basePathname}${queryParams}`;
  }

  public deserialize(url: string): FilterValuesObject {
    return FilterMapper.parseFilterMap(FilterMapper.parseQueryString(url));
  }
}
