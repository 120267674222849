import { usePrevious } from '@finn/auto-ui/hooks/usePrevious';
import { useRouter } from 'next/router';
import React from 'react';

import { useTrackingEffect } from '~/hooks/useTrackingEffect';
import { defaultFilterValues } from '~/modules/combinedFilters/utils';

import { useFilterValues } from '../context';
import { useFiltersResponse } from '../utils';

type FiltersTrackingProps = {
  total: number;
};

export const FiltersTracking: React.FC<FiltersTrackingProps> = ({ total }) => {
  const { isReady: isRouterReady } = useRouter();
  const filtersResponse = useFiltersResponse();
  const trackedFilterValues = useFilterValues();
  const previousFilterValues = usePrevious(
    trackedFilterValues,
    defaultFilterValues
  );

  useTrackingEffect(
    total,
    isRouterReady,
    filtersResponse,
    trackedFilterValues,
    previousFilterValues
  );

  return null;
};

export default React.memo(FiltersTracking);
