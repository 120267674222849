import { FilterValuesObject } from '@finn/ua-vehicle';
import React, { memo, PropsWithChildren, useMemo } from 'react';

import { FilterProvider } from '../context';
import { QueryFilterMapper } from '../mappers/QueryFilterMapper';

type QueryFilterProviderProps = {
  basePathname: string;
  defaultFilterValues?: FilterValuesObject;
};

export const QueryFilterProvider: React.FunctionComponent<
  PropsWithChildren<QueryFilterProviderProps>
> = memo(function QueryFilterProvider({ basePathname, children }) {
  const filterMapper = useMemo(
    () => new QueryFilterMapper(basePathname),
    [basePathname]
  );

  return (
    <FilterProvider filterMapper={filterMapper}>{children}</FilterProvider>
  );
});
