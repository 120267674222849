import { Brand, Color, PricingType } from '../contexts/Vehicle';

export type AvailableFilters = {
  availability: string[];
  brands: Brand[];
  cartypes: string[];
  models: string[];
  gearshifts: string[];
  terms: number[];
  fuels: string[];
  colors: Color[];
  has_hitch: boolean[];
  is_young_driver: boolean[];
  min_price: number;
  max_price: number;
  min_price_msrp: number;
  max_price_msrp: number;
  is_for_business: boolean[];
  view?: VehicleView;
  total_results: number;
};

export type Filters = {
  sort?: string;
  sort_co2?: string;
  brands?: Array<string>;
  cartypes?: Array<string>;
  models?: Array<string>;
  gearshifts?: Array<string>;
  terms?: Array<string>;
  pricing_type?: PricingType;
  fuels?: Array<string>;
  colors?: Array<string>;
  view?: VehicleView;
  'coming-soon'?: Array<string> | string;
  retention?: Array<string> | string;
  has_hitch?: boolean;
  is_young_driver?: boolean;
  is_for_business?: boolean;
  min_price?: string;
  max_price?: string;
  has_deals?: boolean;
  available_from?: string;
  available_to?: string;
  offer_type?: string;
  sort_by_popularity?: boolean;
  zipcode?: string;
};

export type Option<T = string> = {
  label: string;
  value: T;
  disabled?: boolean;
};

export type AvailabilityMap = {
  [date: string]: { startDate: string; endDate: string };
};

export enum VehicleView {
  AVAILABLE = 'available_cars',
  AVAILABLE_AND_COMING_SOON = 'available-and-coming-soon',
  COMING_SOON = 'coming_soon',
  DISPLAYED_CARS = 'displayed_cars',
}

export type InitialFilterKeysMap = { [x: string]: { [x: string]: string } };
