import { FilterValuesObject, parseFilterValues } from '@finn/ua-vehicle';
import { useMemo } from 'react';

import { useDebouncedValue } from '~/hooks/useDebouncedValue';
import {
  useFilterValues,
  useImplicitFilters,
} from '~/modules/products/filters';

import { useProductListItems } from './api';

const REQUEST_DEBOUNCE_MS = 250;

export function useResultsCount(
  nextFilters?: FilterValuesObject,
  delay = REQUEST_DEBOUNCE_MS
) {
  const filterValues = useFilterValues();
  const implicitFilters = useImplicitFilters();

  const filters = useMemo(
    () => ({
      filters: parseFilterValues({
        ...implicitFilters,
        ...filterValues,
        ...nextFilters,
      }),
      zipCode: undefined,
      limit: 0,
    }),
    [implicitFilters, filterValues, nextFilters]
  );

  const params = useDebouncedValue(filters, delay);

  return useProductListItems(params).total ?? 0;
}
