/* eslint-disable max-lines */
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { FilterValuesObject } from '@finn/ua-vehicle';
import { slugify } from '@finn/ui-utils';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import { stringify } from 'query-string';
import { ParsedUrlQuery } from 'querystring';

import { getFilterConfigByUrlParams } from '~/contexts/Filter/utils/getFilterConfig';
import { UrlFilter } from '~/types/checkout';
import {
  AvailableFilters,
  Filters,
  InitialFilterKeysMap,
} from '~/types/filter';

import {
  firstLvlPathParamsKeys,
  secondLvlPathParamsKeys,
  thirdLvlQueryParamsKeys,
} from './constants';
import { readFirstPathLevel, readSecondPathLevel } from './urlParser';

export const stringifyFilter = (filterValues: Filters) => {
  return stringify(filterValues, {
    arrayFormat: 'comma',
    skipNull: true,
  });
};

// We can't use react-intl because we are using this function in server-side
export const getTermUrlName = (term: number) =>
  `${term}-${term > 1 ? 'monate' : 'monat'}`;

// This function take lvl filters & current filter config
// Loop through the lvl filters & get the current values from the filter config
// Then if the filter key has a value(Array not null) it will join them to string by "."
// then it will join the filter group by "_"
const getUrlLvl = (filterKeys: string[], filters: Filters) =>
  filterKeys
    .map((filterKey) => {
      let filterValueList = get(filters, filterKey);

      if (!filterValueList) {
        filterValueList = [];
      } else if (!Array.isArray(filterValueList)) {
        filterValueList = [filterValueList];
      }

      return filterValueList
        .map((filterValue: string) => {
          if (filterKey === 'terms') {
            // Because we need to add "Monat, Monate" wording to the term in the url
            return getTermUrlName(Number(filterValue));
          } else {
            return slugify(filterValue);
          }
        })
        .join('.');
    })
    .filter((filterGroup) => Boolean(filterGroup))
    .join('_');

// This function use getUrlLvl function for every lvl
// Then if the returned value is valid it will push it to the url
// For lvl3 it will pick the lvl3 values from the filter config && stringify the result and add them to the url
// in the end it will check if it should add fahrzeuge/Kaufen to the url or no
type GetUrlFromFilter = (
  filters: Filters,
  useQueryParamsOnly?: boolean
) => string;

export const getUrlFromFilter: GetUrlFromFilter = (
  filters,
  useQueryParamsOnly
) => {
  let url = '';
  if (useQueryParamsOnly) {
    const query = toLower(stringifyFilter(filters));

    if (query) {
      url = `?${toLower(stringifyFilter(filters))}`;
    }

    return url;
  }

  const lvl1 = getUrlLvl(Object.values(firstLvlPathParamsKeys), filters);
  if (lvl1) {
    url = `${url}/${lvl1}`;
  }

  const lvl2 = getUrlLvl(secondLvlPathParamsKeys, filters);
  if (lvl2) {
    url = `${url}/${lvl2}`;
  }

  const lvl3Filter = pick(filters, thirdLvlQueryParamsKeys);
  const lvl3 = toLower(stringifyFilter(lvl3Filter));

  if (lvl3) {
    url = `${url}?${lvl3}`;
  }

  return `/${BASE_SUBSCRIPTION_PATH}${url}`;
};

/*
- This function create a filter values map from lvl1 + lvl2 filters
- Output example:
    {
      : { type: 'gearshifts', name: 'automatik', displayedName: 'Automatik' }
    }
- We need this to check if this word in url is valid or no & check in which lvl
*/

export const getInitialFilterKeysMap = (
  allAvailableFilters: AvailableFilters
): InitialFilterKeysMap => {
  const unavailableBrands = allAvailableFilters.brands
    ?.filter((brand) => !brand.available)
    .map((brand) => ({
      brands: brand.id,
    }));
  const availableBrands = allAvailableFilters.brands
    ?.filter((brand) => brand.available)
    .map((brand) => ({
      brands: brand.id,
    }));
  const cartypes = allAvailableFilters.cartypes.map((cartype) => ({
    cartypes: cartype,
  }));
  const models = allAvailableFilters.models.map((model) => ({ models: model }));
  const fuels = allAvailableFilters.fuels.map((fuel) => ({ fuels: fuel }));
  const gearshifts = allAvailableFilters.gearshifts.map((gearshift) => ({
    gearshifts: gearshift,
  }));

  const initialFilterKeys = [
    ...unavailableBrands,
    ...availableBrands,
    ...cartypes,
    ...models,
    ...gearshifts,
    ...fuels,
  ];
  const initialFilterMap = initialFilterKeys.reduce((acc, curr) => {
    const [filterInfos] = Object.entries(curr);
    const filterKey = slugify(filterInfos[1]);
    acc[filterKey] = {
      type: filterInfos[0],
      name: filterInfos[1]?.toLowerCase(),
      displayedName: filterInfos[1],
    };

    return acc;
  }, {} as InitialFilterKeysMap);

  // Add terms to map
  allAvailableFilters.terms?.forEach((term) => {
    // We can't use intl because we are using this in server-side
    const termUrlPath = getTermUrlName(term);
    const termDisplayedName = `${term} ${term > 1 ? 'Monate' : 'Monat'}`;

    initialFilterMap[termUrlPath] = {
      type: 'terms',
      name: `${term}`,
      displayedName: termDisplayedName,
    };
  });

  initialFilterMap['coming-soon'] = {
    // TODO: Remove after launching retention
    type: 'coming-soon',
    name: 'coming-soon',
    displayName: 'coming-soon',
  };

  initialFilterMap.retention = {
    type: 'retention',
    name: 'retention',
    displayName: 'retention',
  };

  return initialFilterMap;
};

interface IUrlFilters {
  firstLevel: UrlFilter;
  secondLevel: UrlFilter;
  queryParams: any;
}
interface IGetFiltersFromUrlParams {
  allAvailableFilters: AvailableFilters;
  allAvailableFiltersExtended: AvailableFilters;
  pathParams: string[];
  urlQuery?: ParsedUrlQuery;
}
interface IGetFiltersFromUrl {
  (params: IGetFiltersFromUrlParams):
    | (IUrlFilters & {
        shouldShowOutOfStock: boolean;
      })
    | null;
}

export const getFiltersFromUrl: IGetFiltersFromUrl = ({
  allAvailableFilters,
  allAvailableFiltersExtended,
  pathParams,
  urlQuery,
}) => {
  let shouldShowOutOfStock = false;
  const filterKeysMap = getInitialFilterKeysMap(allAvailableFilters);
  const extendedFilterKeysMap = getInitialFilterKeysMap(
    allAvailableFiltersExtended
  );

  let firstLevel = readFirstPathLevel(pathParams[0], filterKeysMap);

  if (isEmpty(firstLevel)) {
    const extendedFirstLevel = readFirstPathLevel(
      pathParams[0],
      extendedFilterKeysMap
    );
    if (isEmpty(extendedFirstLevel)) {
      return null;
    }

    shouldShowOutOfStock = true;
    firstLevel = extendedFirstLevel;
  }

  let secondLevel = {};
  if (pathParams.length === 2) {
    secondLevel = readSecondPathLevel(pathParams[1], filterKeysMap);
    if (isEmpty(secondLevel)) {
      const extendedSecondLevel = readSecondPathLevel(
        pathParams[1],
        extendedFilterKeysMap
      );
      if (isEmpty(extendedSecondLevel)) {
        return null;
      }

      shouldShowOutOfStock = true;
      secondLevel = extendedSecondLevel;
    }
  }

  const queryParams = getFilterConfigByUrlParams(urlQuery);

  return {
    firstLevel,
    secondLevel,
    queryParams,
    shouldShowOutOfStock,
  };
};

// This function used to get noindex-nofollow when the filter change
// It depend on hte structure ofn the url && results
export const getNoIndexNoFollow = (
  filter: FilterValuesObject,
  url: string,
  totalCount: number
) => {
  const lvl1Filter = pick(filter, Object.values(firstLvlPathParamsKeys));
  const lvl2Filter = pick(filter, secondLvlPathParamsKeys);
  const lvl3Filter = pick(filter, thirdLvlQueryParamsKeys);

  //NOINDEX,FOLLOW filtering by params included
  const isLvl3Empty = every(lvl3Filter, isEmpty);
  if (!isLvl3Empty) {
    return true;
  }

  // NOINDEX,FOLLOW multiple filters in URL - multiple selections from same filter in lvl1 or lvl2
  const isMultipleFeatures = url.split('.').length > 1;
  if (isMultipleFeatures) {
    return true;
  }

  // INDEX,FOLLOW /subscribe page - unfiltered PLP
  const isLvl1Empty = every(lvl1Filter, isEmpty);
  const isLvl2Empty = every(lvl2Filter, isEmpty);
  const isNoLevels = isLvl1Empty && isLvl2Empty;
  if (isNoLevels) {
    return false;
  }

  const isLvl1Only = !isLvl1Empty && isLvl2Empty;

  //INDEX,FOLLOW single brand / single brand + single model
  const isSingleBrandWithSingleOrZeroModel =
    isLvl1Only &&
    lvl1Filter.brands?.length === 1 &&
    (lvl1Filter.models?.length === undefined ||
      lvl1Filter.models?.length === 1) &&
    totalCount;
  if (isSingleBrandWithSingleOrZeroModel) {
    return false;
  }

  // NOINDEX,FOLLOW single brand + multiple models
  const isSingleBrandMultiModel =
    lvl1Filter.brands?.length === 1 && lvl1Filter.models?.length > 1;
  if (isSingleBrandMultiModel) {
    return true;
  }

  // INDEX,FOLLOW single brand + single car type / single brand + single fuel type
  const isSingleBrandOnlyWithFuelOrCarType =
    lvl1Filter.brands?.length === 1 &&
    lvl1Filter.models?.length === undefined &&
    ((lvl2Filter.fuels?.length === 1 &&
      lvl2Filter.cartypes?.length === undefined) ||
      (lvl2Filter.cartypes?.length === 1 &&
        lvl2Filter.fuels?.length === undefined)) &&
    totalCount;
  if (isSingleBrandOnlyWithFuelOrCarType) {
    return false;
  }

  // INDEX,FOLLOW /fahrzeuge/feature pages if total count > 0
  // (it was > 2 but looks like that removes puts electric for US as noIndex so changing)
  const isLevel2Only = isLvl1Empty && !isLvl2Empty;
  if (isLevel2Only && totalCount) {
    const secondLvl = url
      .replace(`/${BASE_SUBSCRIPTION_PATH}`, '')
      .split('/')[1];
    if (secondLvl) {
      const secondLvlGroupsLength = secondLvl.split('_').length;
      const featureGroupLength = secondLvl.split('.').length;
      const singleFuelAndCarType =
        secondLvlGroupsLength === 2 &&
        Object.keys(filter).every((key) => ['cartypes', 'fuels'].includes(key));

      if (
        singleFuelAndCarType ||
        (secondLvlGroupsLength === 1 && featureGroupLength === 1)
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

/*
  - Get when open Subscribe this require allow different structures to the url
  - lvl1 can be /brands_Models , etc...  || /feature1a.feature1b.feature1c_feature2a , etc..
  - steps:
    - Check lvls number
    - if isMoreOneLvl it should be /brands_Models/feature1a.feature1b.feature1c_feature2a
    - if one lvl it should be /brands_Models || /feature1a.feature1b.feature1c_feature2a
*/

export const getSubscribePathParamsFilter = (
  allAvailableFilters: AvailableFilters,
  allAvailableFiltersExtended: AvailableFilters,
  pathParams: string[],
  urlQuery?: ParsedUrlQuery
) => {
  const isMoreOneLvl = pathParams.length > 1;

  // /subscribe/lvl1/lvl2
  if (isMoreOneLvl) {
    return getFiltersFromUrl({
      allAvailableFilters,
      allAvailableFiltersExtended,
      pathParams,
      urlQuery,
    });
  } else {
    // /subscribe/lvl1
    const queryParams = getFilterConfigByUrlParams(urlQuery);
    const commonFilters = {
      queryParams,
      firstLevel: {},
      secondLevel: {},
      shouldShowOutOfStock: false,
    };

    const filterKeysMap = getInitialFilterKeysMap(allAvailableFilters);
    const extendedFilterKeysMap = getInitialFilterKeysMap(
      allAvailableFiltersExtended
    );

    const firstLevel = readFirstPathLevel(pathParams[0], filterKeysMap);
    if (!isEmpty(firstLevel)) {
      return {
        ...commonFilters,
        firstLevel,
      };
    }

    const extendedFirstLevel = readFirstPathLevel(
      pathParams[0],
      extendedFilterKeysMap
    );
    if (!isEmpty(extendedFirstLevel)) {
      return {
        ...commonFilters,
        firstLevel: extendedFirstLevel,
        shouldShowOutOfStock: true,
      };
    }

    const secondLevel = readSecondPathLevel(pathParams[0], filterKeysMap);
    if (!isEmpty(secondLevel)) {
      return {
        ...commonFilters,
        secondLevel,
      };
    }

    const extendedSecondLevel = readSecondPathLevel(
      pathParams[0],
      extendedFilterKeysMap
    );
    if (!isEmpty(extendedSecondLevel)) {
      return {
        ...commonFilters,
        secondLevel: extendedSecondLevel,
        shouldShowOutOfStock: true,
      };
    }

    return null;
  }
};

// Get filter config directly from URL
export const getFilterConfigFromUrl = (
  initialFilter: AvailableFilters,
  pathParams: string[],
  urlQuery: ParsedUrlQuery,
  useQueryParamsOnly?: boolean
) => {
  if (!initialFilter) return {};

  const queryParams = getFilterConfigByUrlParams(urlQuery, useQueryParamsOnly);
  const filter = {
    ...queryParams,
  };
  const filterKeysMap = getInitialFilterKeysMap(initialFilter);

  // subscribe/lvl1/lvl2
  if (pathParams.length > 1) {
    // Read first level filters
    const firstLevelFilters = readFirstPathLevel(pathParams[0], filterKeysMap);
    if (isEmpty(firstLevelFilters)) {
      return filter;
    }

    // Read second level filters
    const secondLevelFilters = readSecondPathLevel(
      pathParams[1],
      filterKeysMap
    );
    if (isEmpty(secondLevelFilters)) {
      return filter;
    }

    return {
      ...filter,
      ...firstLevelFilters,
      ...secondLevelFilters,
    };
  } else if (pathParams.length === 1) {
    // /subscribe/lvl1
    const firstLevelFilters = readFirstPathLevel(pathParams[0], filterKeysMap);
    if (!isEmpty(firstLevelFilters)) {
      return {
        ...filter,
        ...firstLevelFilters,
      };
    }
    // /subscribe/lvl2
    const secondLevelFilters = readSecondPathLevel(
      pathParams[0],
      filterKeysMap
    );
    if (!isEmpty(secondLevelFilters)) {
      return {
        ...filter,
        ...secondLevelFilters,
      };
    }
  }

  return filter;
};
